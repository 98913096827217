<template>
  <v-container>
    <div class="container-global">
      <v-row>
        <v-col col="12" sm="12" md="6">
          <h2 class="mb-12">Crear encuesta</h2>
          <v-form
            ref="formEncuesta"
            v-model="valid"
            lazy-validation
            @submit.prevent="guardaEncuesta()"
          >
            <div class="d-flex align-center mb-4">
              <p class="text-caption negroClaro mb-0 mr-1">
                Información General
              </p>
              <v-divider></v-divider>
            </div>
            <v-text-field
              color="black"
              v-model="dataEncuesta.nombre"
              :rules="nameRules"
              label="Nombre"
              required
              outlined
            ></v-text-field>
            <v-textarea
              label="Descripcion de la encuesta"
              v-model="dataEncuesta.descripcion"
              no-resize
              color="black"
              rows="3"
              outlined
            ></v-textarea>
            <v-select
              :items="listRestaurantes"
              :item-text="'nombre'"
              :item-value="'slug'"
              v-model="dataEncuesta.slug"
              :rules="restauranteRules"
              name="listRestaurantes"
              label="Selecciona un restaurante"
              color="black"
              required
              class="mb-4"
              outlined
            ></v-select>
            <div class="d-flex align-center mb-4">
              <p class="text-caption negroClaro mb-0 mr-1">Preguntas</p>
              <v-divider></v-divider>
            </div>
            <p class="black--text text-h6">Lista de preguntas</p>
            <p>Puedes agregar preguntas a la encuesta</p>
            <v-btn
              color="black"
              text
              class="black--text"
              @click="mostrarModalAgregar()"
            >
              <v-icon right dark class="ml-0 mr-2"> mdi-plus </v-icon>
              Agregar pregunta
            </v-btn>
            <div class="preguntas-add">
              <ul class="list-preguntas" v-if="listPreguntas.length > 0">
                <draggable v-model="listPreguntas" @change="changeOrder()">
                  <li v-for="(pregunta, index) in listPreguntas" :key="index">
                    {{ pregunta.texto }}
                    <div class="actions">
                      <button
                        type="button"
                        @click="editarPregunta(pregunta)"
                        class="mr-4"
                      >
                        <img
                          width="18px"
                          :src="require('@/assets/icon-edit.svg')"
                          alt="icon edit"
                        />
                      </button>
                      <button
                        type="button"
                        @click="deletePregunta(pregunta)"
                        class="mr-4"
                      >
                        <img
                          width="15.8px"
                          src="@/assets/icon-delete.svg"
                          alt="icon delete"
                        />
                      </button>
                    </div>
                  </li>
                </draggable>
              </ul>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="white--text pr-8 pl-8 btn-margin"
                :disabled="!valid"
                :loading="loading"
                type="submit"
                >Guardar encuesta</v-btn
              >
            </div>
          </v-form>
          <!-- modal agregar pregunta -->
          <v-dialog v-model="dialogPregunta" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitlePregunta }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        label="Descripcion"
                        v-model="objEditPregunta.texto"
                        no-resize
                        color="black"
                        rows="3"
                        outlined
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="errorColor" link text @click="close()"
                  >Cancelar</v-btn
                >
                <v-btn
                  v-on:click="saveGlobal()"
                  :disabled="!validDescripcion"
                  link
                  text
                  color="black"
                  >Guardar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- modal agregar pregunta -->
          <!-- modal eliminar pregunta -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Estas seguro que deseas eliminar esta pregunta?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="errorColor" text @click="closeDelete()"
                  >Cancelar</v-btn
                >
                <v-btn color="primary" text @click="confirmDeletePregunta()"
                  >Confirmar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- modal eliminar pregunta -->
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("moduloRestaurantes", ["listRestaurantes"]),
    validDescripcion() {
      return this.objEditPregunta.texto !== "" ? true : false;
    },
    formTitlePregunta() {
      return this.objEditIndex === -1 ? "Agregar pregunta" : "Editar pregunta";
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      valid: false,
      loading: false,
      nameRules: [(v) => !!v || "El nombre es requerido"],
      restauranteRules: [(v) => !!v || "El restaurante es requerido"],
      listPreguntas: [],
      objEditPregunta: {
        texto: "",
        order: 0,
      },
      defaultEditPregunta: {
        texto: "",
        order: 0,
      },
      objEditIndex: -1,
      dialogPregunta: false,
      dialogDelete: false,
      dataEncuesta: {
        nombre: "",
        descripcion: "",
        slug: "",
        preguntas: [],
      },
      order: 0,
    };
  },
  created() {
    this.obtieneRestaurantes();
  },
  watch: {
    dialogPregunta(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["obtieneRestaurantes"]),
    ...mapActions("moduloEncuestas", ["creaEncuestaData"]),
    guardaEncuesta() {
      this.dataEncuesta.preguntas = this.listPreguntas;
      if (this.dataEncuesta.preguntas.length > 0) {
        this.sendCreateEncuesta();
      } else {
        this.valid = false;
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Aun no has agregado preguntas.",
        });
      }
    },
    async sendCreateEncuesta() {
      //se validan los campos
      if (!this.$refs.formEncuesta.validate()) return;
      this.loading = true;
      const result = await this.creaEncuestaData(this.dataEncuesta);
      if (result.res) {
        this.dataEncuesta.preguntas = [];
        this.listPreguntas = [];
        this.reset();
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó la encuesta correctamente.",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    mostrarModalAgregar() {
      this.dialogPregunta = true;
    },
    saveGlobal() {
      if (this.objEditIndex > -1) {
        Object.assign(
          this.listPreguntas[this.objEditIndex],
          this.objEditPregunta
        );
      } else {
        this.agregarPregunta();
      }
      this.close();
    },
    agregarPregunta() {
      this.objEditPregunta.order = this.order;
      this.listPreguntas.push(this.objEditPregunta);
      this.order++;
    },
    editarPregunta(item) {
      this.objEditIndex = this.listPreguntas.indexOf(item);
      this.objEditPregunta = Object.assign({}, item);
      this.dialogPregunta = true;
    },
    deletePregunta(item) {
      this.objEditIndex = this.listPreguntas.indexOf(item);
      this.objEditPregunta = Object.assign({}, item);
      this.dialogDelete = true;
    },
    confirmDeletePregunta() {
      this.listPreguntas.splice(this.objEditIndex, 1);
      this.changeOrder();
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.objEditPregunta = Object.assign({}, this.defaultEditPregunta);
        this.objEditIndex = -1;
      });
    },
    //resetea la modal
    close() {
      this.dialogPregunta = false;
      this.$nextTick(() => {
        this.objEditPregunta = Object.assign({}, this.defaultEditPregunta);
        this.objEditIndex = -1;
      });
    },
    reset() {
      this.$refs.formEncuesta.reset();
    },
    changeOrder() {
      this.listPreguntas.map(function (item, index) {
        item.order = index;
        return item;
      });
    },
  },
};
</script>
<style lang="scss">
.preguntas-add {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 40px;
}
.btn-actions {
  margin-top: 50px;
}
.list-preguntas {
  padding-left: 0 !important;
  list-style: none;
  border-radius: 8px;
  li {
    background-color: #ebebeb;
    padding: 16px;
    padding-right: 80px;
    position: relative;
    cursor: move;
    .actions {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
}
</style>